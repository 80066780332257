// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-password-recovery-tsx": () => import("./../../../src/pages/password/recovery.tsx" /* webpackChunkName: "component---src-pages-password-recovery-tsx" */),
  "component---src-pages-password-reset-tsx": () => import("./../../../src/pages/password/reset.tsx" /* webpackChunkName: "component---src-pages-password-reset-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-terminos-y-condiciones-plataforma-tsx": () => import("./../../../src/pages/terminos-y-condiciones-plataforma.tsx" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-plataforma-tsx" */),
  "component---src-pages-verificar-cuenta-tsx": () => import("./../../../src/pages/verificar-cuenta.tsx" /* webpackChunkName: "component---src-pages-verificar-cuenta-tsx" */)
}

