import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import dayjs from 'dayjs'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Formater function
import { formater } from './formater'

// Import locales http://www.lingoes.net/en/translator/langcode.htm
import enGB from './locales/en-GB.json'
import esES from './locales/es-ES.json'
import 'dayjs/locale/es'
import 'dayjs/locale/en-gb'

// Set dayjs plugins
dayjs.extend(LocalizedFormat)

export const languages = [
  ['es', 'Español'],
  ['en', 'English'],
]
export const defaultLanguage = 'es'

// Events
// Must be before initialization to work
i18n.on('languageChanged', lng => {
  switch (lng.toLowerCase()) {
    case 'es-es':
      dayjs.locale('es')
      break

    default:
      dayjs.locale(lng.toLowerCase())
      break
  }
})

i18n.use(initReactI18next).init({
  resources: {
    'en-GB': enGB,
    en: enGB,
    es: esES,
  },
  fallbackLng: 'es',
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    format: formater,
  },
})

export default i18n
