import { Config } from '@/config'
import { useToggle } from '@/hooks/use-toggle'
import { isBrowser } from '@/utils'
import axios from 'axios'
import Big from 'big.js'
import dayjs from 'dayjs'
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  User,
  UserCreation,
  UserCredentials,
  UserKYCStatus,
  UserPaymentStatus,
  UserTransaction,
  UserUpdate,
} from './entity'
import { getMe, getVerifyIdentityUrl, login, register, updateAccount } from './service'

interface AuthContext {
  user: User | null
  isAuthenticated: boolean
  isAuthenticating: boolean
  isPaymentDelay: boolean
  token: string | null
  signin: (
    credentials: UserCredentials & { remember: boolean; reCaptchaToken: string }
  ) => Promise<void>
  signup: (user: UserCreation) => Promise<void>
  signout: () => void
  update: (user: UserUpdate) => Promise<void>
  verifyIdentity: () => Promise<void>
  addStateTransaction: (transaction: UserTransaction) => void
  changeKYCStatus: (kycStatus: UserKYCStatus) => void
  changePaymentStatus: (paymentStatus: UserPaymentStatus) => void
  addPaymentDelay: () => void
  removePaymentDelay: () => void
}

const authContext = createContext<AuthContext | null>(null)

export const useAuth = (): AuthContext | null => {
  return useContext(authContext)
}

const useProvideAuth = () => {
  const [user, setUser] = useState<User | null>(null)
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true)

  // Get token from LocalStorage
  const getToken = useCallback(() => {
    return isBrowser() ? window.localStorage.getItem(Config.ACCESS_TOKEN_STORAGE_NAME) : null
  }, [])

  // Get payment delay expire date from LocalStorage
  const getPaymentDelay = useCallback(() => {
    return isBrowser() ? window.localStorage.getItem(Config.PAYMENT_DELAY_STORAGE_NAME) : null
  }, [])

  const [token, setToken] = useState<string | null>(getToken())
  const [isPaymentDelay, toggleIsPaymentDelay] = useToggle(
    !!(getPaymentDelay() && dayjs().isBefore(getPaymentDelay()))
  )

  // Set token in LocalStorage annd context
  const saveToken = useCallback((tkn: string) => {
    if (isBrowser()) {
      window.localStorage.setItem(Config.ACCESS_TOKEN_STORAGE_NAME, tkn)
      setToken(tkn)
    }
  }, [])

  // SignIn to get ann auth token
  const signin = useCallback(
    async ({
      remember,
      reCaptchaToken,
      ...credentials
    }: UserCredentials & {
      remember: boolean
      reCaptchaToken: string
    }): Promise<void> => {
      const { accessToken } = await login(credentials, {
        [Config.RECAPTCHA_HEADER]: reCaptchaToken,
      })

      if (remember) {
        saveToken(accessToken)
      } else {
        setToken(accessToken)
      }
    },
    [saveToken]
  )

  // SignOut
  const signout = useCallback(() => {
    if (isBrowser()) {
      window.localStorage.removeItem(Config.ACCESS_TOKEN_STORAGE_NAME)
    }
    setToken(null)
    setUser(null)
  }, [])

  // Authenticate the auth token user
  const authenticate = useCallback(async (): Promise<void> => {
    if (token) {
      setIsAuthenticating(true)
      try {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
        const responseUser = await getMe()
        setUser(responseUser)
      } catch (error) {
        signout()
      }

      setIsAuthenticating(false)
    }
  }, [token, signout])

  // Effect to authenticate if has a Bearer Token
  useEffect(() => {
    if (isBrowser() && token) {
      authenticate()
    }
  }, [authenticate, token])

  // SignUp to create a new user account
  const signup = useCallback(async (newUser: UserCreation) => {
    await register(newUser)
  }, [])

  // Update user account details
  const update = useCallback(
    async (userUpdate: UserUpdate) => {
      await updateAccount(userUpdate)
      if (user) setUser({ ...user, ...userUpdate })
    },
    [user]
  )

  // Verify user identity
  const verifyIdentity = useCallback(async () => {
    if (user && !user.verified && isBrowser()) {
      const verifyUrl = await getVerifyIdentityUrl()
      window.location.assign(verifyUrl)
    }
  }, [user])

  // Get token from LocalStorage
  const addStateTransaction = useCallback(
    (transaction: UserTransaction) => {
      window.analytics?.track('CUSTOMER_BUY_TOKENS', {
        Tokens: transaction.tokenAmount,
        Quantity: transaction.totalTokenEur,
        'Payment method': transaction.type.toUpperCase(),
      })

      if (user) {
        setUser({
          ...user,
          transactions: [...user.transactions, transaction],
          totalTokenAmount: new Big(user.totalTokenAmount).plus(transaction.tokenAmount).toString(),
        })
      }
    },
    [user]
  )

  const changeKYCStatus = useCallback(
    (kycStatus: UserKYCStatus) => {
      if (kycStatus === UserKYCStatus.APPROVED) {
        window.analytics?.track('CUSTOMER_VERIFY_IDENTITY')
      } else if (kycStatus !== UserKYCStatus.PENDING) {
        window.analytics?.track('CUSTOMER_VERIFY_IDENTITY_ERROR', {
          Status: kycStatus,
        })
      }

      if (user) {
        setUser({
          ...user,
          kycStatus,
          verified: kycStatus === UserKYCStatus.APPROVED,
        })
      }
    },
    [user]
  )

  const changePaymentStatus = useCallback(
    (paymentStatus: UserPaymentStatus) => {
      if (user) {
        setUser({
          ...user,
          paymentStatus,
        })
      }
    },
    [user]
  )

  // Flag to check if the user is authenticated
  const isAuthenticated = useMemo(() => !!(token && user), [token, user])

  const addPaymentDelay = useCallback(() => {
    // Add 1 minute from current date and parse to unix ms timestamp
    const delayExpire = dayjs().add(1, 'minute')
    window.localStorage.setItem(
      Config.PAYMENT_DELAY_STORAGE_NAME,
      String(delayExpire.toDate().getTime())
    )
    toggleIsPaymentDelay(true)
  }, [toggleIsPaymentDelay])

  const removePaymentDelay = useCallback(() => {
    window.localStorage.removeItem(Config.PAYMENT_DELAY_STORAGE_NAME)
    toggleIsPaymentDelay(false)
  }, [toggleIsPaymentDelay])

  // Effect to check if user has a payment delay
  useEffect(() => {
    const interval = setInterval(function () {
      // Get timestamp string of delay finish if exist in localStorage
      const paymentDelayStored = getPaymentDelay()

      if (paymentDelayStored && dayjs().isBefore(Number(paymentDelayStored))) {
        toggleIsPaymentDelay(true)
      } else {
        removePaymentDelay()
      }

      // If the count down is over, write some text
      if (!token) {
        clearInterval(interval)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [token, getPaymentDelay, toggleIsPaymentDelay, removePaymentDelay])

  // Return the user object and auth methods
  return {
    user: user,
    isAuthenticated,
    isAuthenticating,
    isPaymentDelay,
    token,
    signin,
    signup,
    signout,
    update,
    verifyIdentity,
    addStateTransaction,
    changeKYCStatus,
    changePaymentStatus,
    addPaymentDelay,
    removePaymentDelay,
  }
}

export const AuthProvider = ({ children }: PropsWithChildren<never>): JSX.Element => {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}
