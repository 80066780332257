/* eslint-disable @typescript-eslint/no-var-requires */
const { nsRoutes: nsRoutesES } = require('./locales/es-ES.json')
const { nsRoutes: nsRoutesEN } = require('./locales/en-GB.json')

exports.pageRouteTranslations = Object.fromEntries(
  Object.entries(nsRoutesES).map(([keyPath, pathES]) => {
    return [
      keyPath,
      {
        es: pathES,
        en: nsRoutesEN[keyPath],
      },
    ]
  })
)
