/* eslint-disable @typescript-eslint/explicit-module-boundary-types, react/jsx-filename-extension */
import './src/styles.css'

import { AuthProvider } from './src/features/auth/use-auth'
import { CookiesConfigProvider } from './src/features/cookies/use-cookies'
import { I18nextProvider } from 'react-i18next'
import { NotificationContainer } from './src/components/NotificationContainer'
import i18n from './src/i18n'
import queryClient from './src/query-client'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { FeatureFlagsProvider } from '@/features/flags'

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    <FeatureFlagsProvider>
      <ReactQueryDevtools />
      <I18nextProvider i18n={i18n}>
        <CookiesConfigProvider>
          <AuthProvider>
            {element}
            <NotificationContainer />
          </AuthProvider>
        </CookiesConfigProvider>
      </I18nextProvider>
    </FeatureFlagsProvider>
  </QueryClientProvider>
)
