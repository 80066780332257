export interface UserTransaction {
  _id: string
  userId: string
  phase: 'marketing' | 'private' | 'phaseOne' | 'phaseTwo'
  tokenAmount: number
  totalTokenEur: string
  vestingDetails: { tokens: string; percentage: string; startDate: number }[]
  tokenPriceEur: string
  type: 'fiat' | 'crypto'
  createdAt: string
  updatedAt: string
}

export enum UserKYCStatus {
  NOT_VALIDATED = 'NOT_VALIDATED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  EXPIRED = 'EXPIRED',
  DENIED = 'DENIED',
  SUSPECTED = 'SUSPECTED',
}

export enum UserPaymentStatus {
  NONE = 'NONE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}
export interface User {
  _id: string
  name: string
  surnames: string
  email: string
  phone: string
  publicAddress?: string
  verified: boolean
  kycStatus: UserKYCStatus
  paymentStatus: UserPaymentStatus
  totalTokenAmount: string
  transactions: UserTransaction[]
}

export interface UserCreation {
  name: string
  surnames: string
  email: string
  phone: string
  password: string
  verificationUrl: string
  language: string
}

export interface UserUpdate {
  verificationCode: string
  publicAddress?: string
}

export interface UserCredentials {
  email: string
  password: string
}
