import { Config } from '@/config'
import { getTranslatedPath } from '@/i18n/utils'
import { PAGES } from '@/routes'
import axios from 'axios'
import { User, UserCreation, UserCredentials, UserUpdate } from './entity'

export const login = async (
  credentials: UserCredentials,
  headers?: Record<string, string>
): Promise<{ accessToken: string }> => {
  const { data } = await axios.post<{ accessToken: string }>(
    `${Config.API_URL}/login`,
    credentials,
    { headers }
  )
  axios.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`
  return data
}

export const logout = (): void => {
  axios.defaults.headers.common.Authorization = ''
}

export const getMe = async (): Promise<User> => {
  const { data } = await axios.get<User>(`${Config.API_URL}/users`)
  return data
}

export const register = async (newUser: UserCreation): Promise<void> => {
  await axios.post<User>(`${Config.API_URL}/register`, newUser)
}

export const getVerifyIdentityUrl = async (): Promise<string> => {
  const { data } = await axios.get<string>(`${Config.API_URL}/verify`, {
    params: new URLSearchParams({
      successUrl: `${window.location.origin}${getTranslatedPath(
        window.location.pathname,
        PAGES.PROFILE
      )}?idenfyStatus=success`,
      errorUrl: `${window.location.origin}${getTranslatedPath(
        window.location.pathname,
        PAGES.PROFILE
      )}?idenfyStatus=error`,
    }),
  })
  return data
}

export const activateAccount = async (token: string): Promise<void> => {
  await axios.post<string>(`${Config.API_URL}/activate`, {
    token,
  })
}

export const requestActivateAccountEmail = async (
  email: string,
  verificationUrl: string
): Promise<void> => {
  await axios.post<void>(`${Config.API_URL}/activate/email`, {
    email,
    verificationUrl,
  })
}

export const updateAccount = async (user: UserUpdate): Promise<void> => {
  await axios.patch<string>(`${Config.API_URL}/users`, user)
}

export const generateVerificationCode = async (): Promise<void> => {
  await axios.get<void>(`${Config.API_URL}/verification/code`)
}

/**
 * Update the current password
 *
 * @param email - user email to send the recovery password
 * @param redirectURL - the page url to use the recovery token
 */
export const passwordRecovery = async (
  email: string,
  redirectUrl: string,
  headers?: Record<string, string>
): Promise<void> => {
  await axios.post<string>(
    `${Config.API_URL}/password/recovery`,
    {
      email,
      redirectUrl,
    },
    {
      headers,
    }
  )
}

/**
 * Update the current password with a password recovery token
 *
 * @param token - The password recovery token
 * @param password - The new password
 */
export const passwordReset = async (
  token: string,
  newPassword: string,
  headers?: Record<string, string>
): Promise<void> => {
  await axios.post<string>(
    `${Config.API_URL}/password/change`,
    {
      token,
      newPassword,
    },
    {
      headers,
    }
  )
}
