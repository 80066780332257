import {
  Button,
  COLOR,
  IconCircle,
  Modal,
  SIZE,
} from '@blockchain-traceability-sl/tailwind-components'
import { CheckIcon, ExclamationIcon } from '@heroicons/react/outline'
import { useRef } from 'react'

export enum AlertType {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export interface AlertProps {
  show: boolean
  type: AlertType
  title: string
  description: string
  confirmText: string
  confirmStyle?: React.CSSProperties
  onConfirm: () => void
}

const typeToColor = {
  [AlertType.ERROR]: COLOR.RED,
  [AlertType.SUCCESS]: COLOR.GREEN,
  [AlertType.WARNING]: COLOR.YELLOW,
}

const typeToIcon = {
  [AlertType.ERROR]: ExclamationIcon,
  [AlertType.SUCCESS]: CheckIcon,
  [AlertType.WARNING]: ExclamationIcon,
}

export const Alert = ({
  show,
  type,
  title,
  description,
  confirmText,
  onConfirm,
  confirmStyle,
}: AlertProps): JSX.Element => {
  const actionButtonRef = useRef<HTMLButtonElement>(null)

  return (
    <Modal
      role='alert'
      show={show}
      dismissOnBlur={false}
      size={SIZE.EXTRA_SMALL}
      initialFocus={actionButtonRef}
    >
      <div className='p-4'>
        <Modal.Title className='whitespace-pre-wrap flex flex-col space-y-4'>
          <IconCircle
            color={typeToColor[type]}
            IconComponent={typeToIcon[type]}
            className='mx-auto'
          />
          <div>{title}</div>
        </Modal.Title>
        <Modal.Body>
          <p className='text-center text-sm text-gray-500 whitespace-pre-wrap'>{description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            innerRef={actionButtonRef}
            type='button'
            className='w-full'
            onClick={onConfirm}
            style={confirmStyle}
          >
            {confirmText}
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  )
}
