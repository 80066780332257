import { memo, useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { NotifyErrorProps, NotifyWarnProps, useNotify } from '@/hooks/use-notify'
import { useToggle } from '@/hooks/use-toggle'
import { Alert, AlertType } from './Alert'

export const NotificationContainer = memo(() => {
  const [show, toggle] = useToggle(false)
  const [currentNotify, setCurrentNotify] =
    useState<{ props: NotifyErrorProps | NotifyWarnProps; type: AlertType }>()
  const { onError, onWarn, onClear } = useNotify()

  useEffect(() => {
    onError(alertProps => {
      setCurrentNotify({ props: alertProps, type: AlertType.ERROR })
      toggle()
    })

    onWarn(alertProps => {
      setCurrentNotify({ props: alertProps, type: AlertType.WARNING })
      toggle()
    })

    onClear(() => {
      setCurrentNotify(undefined)
      toggle(false)
    })
  }, [onError, onWarn, onClear, toggle])

  return (
    <>
      <Toaster position='top-right' />
      {currentNotify && (
        <Alert
          title={currentNotify.props.title}
          description={currentNotify.props.description}
          confirmText={currentNotify.props.actionText}
          type={currentNotify.type}
          show={show}
          onConfirm={() => {
            toggle()
            currentNotify.props.onActionClick && currentNotify.props.onActionClick()
            setCurrentNotify(undefined)
          }}
          confirmStyle={currentNotify.props.confirmStyle}
        />
      )}
    </>
  )
})
