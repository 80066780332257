import { FormatFunction } from 'i18next'
import dayjs from 'dayjs'

import 'dayjs/locale/es'
import 'dayjs/locale/en-gb'
import { formatNumber } from '@/utils/format-number'

export const formater: FormatFunction = (value, rawFormat = '', lng = 'es') => {
  const [format, ...additionalFormatProperties] = rawFormat.split(',').map(v => v.trim())

  // Format Date instances following a format https://day.js.org/docs/en/display/format
  if (value instanceof Date) {
    if (lng) {
      switch (lng.toLowerCase()) {
        case 'es-es':
          dayjs.locale('es')
          break

        default:
          dayjs.locale(lng.toLowerCase())
          break
      }
    }

    return dayjs(value).format(format)
  }

  switch (format) {
    // Format strings replacing white spaces to underscore
    case 'snakecase':
      return value.replace(/ /g, '_').toLowerCase()

    // Format currency amounts replacing white spaces to underscore
    case 'currency':
      return Intl.NumberFormat(lng, {
        style: 'currency',
        currency: additionalFormatProperties[0],
      }).format(Number(value))

    // Format numbers
    case 'number':
      return formatNumber(value, lng)

    default:
      return value
  }
}
