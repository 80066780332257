import { Config } from '@/config'
import { isBrowser } from '@/utils'
import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react'
import { CookiesConfig } from './type'

interface CookiesConfigContext {
  config: CookiesConfig | null
  isConfigured: boolean
  saveConfig: (config: CookiesConfig) => void
}

const cookiesConfigContext = createContext<CookiesConfigContext | null>(null)

export const useCookiesConfig = (): CookiesConfigContext | null => {
  return useContext(cookiesConfigContext)
}

const useProvideCookiesConfig = () => {
  // Get Cookies Config from LocalStorage
  const getConfig = useCallback(() => {
    return isBrowser() && window.localStorage.getItem(Config.COOKIES_CONFIG_STORAGE_NAME)
      ? (JSON.parse(
          window.localStorage.getItem(Config.COOKIES_CONFIG_STORAGE_NAME) as string
        ) as CookiesConfig)
      : null
  }, [])

  const [config, setConfig] = useState<CookiesConfig | null>(getConfig())
  const [isConfigured, setIsConfigured] = useState<boolean>(
    isBrowser() && !!window.localStorage.getItem(Config.COOKIES_CONFIG_STORAGE_NAME)
  )

  // Set token in LocalStorage annd context
  const saveConfig = useCallback((cookiesConfig: CookiesConfig) => {
    if (isBrowser()) {
      window.localStorage.setItem(Config.COOKIES_CONFIG_STORAGE_NAME, JSON.stringify(cookiesConfig))
      setConfig(cookiesConfig)
      setIsConfigured(true)
    }
  }, [])

  return {
    config,
    isConfigured,
    saveConfig,
  }
}

export const CookiesConfigProvider = ({ children }: PropsWithChildren<never>): JSX.Element => {
  const cookiesConfigProvided = useProvideCookiesConfig()
  return (
    <cookiesConfigContext.Provider value={cookiesConfigProvided}>
      {children}
    </cookiesConfigContext.Provider>
  )
}
