export const PAGES = {
  // Pages
  HOME: '/',
  CONTACT: '/contacto/',
  ABOUT: '/sobre-nosotros/',
  SUPPORTS: '/apoyos/',
  PRICING: '/precios/',
  SOLUTIONS: {
    TRACEABILITY: '/soluciones/trazabilidad/',
    DIGITAL_TRANSFORMATION: '/soluciones/transformacion-digital/',
    TRANSPARENCY: '/soluciones/transparencia/',
  },

  // Legal
  PRIVACY_POLICY: '/politica-de-privacidad/',
  PRIVACY_POLICY_PLATFORM: '/politica-de-privacidad-plataforma/',
  COOKIES: '/cookies/',
  LEGAL_NOTICE: '/aviso-legal/',
  TERM_AND_CONDITIONS: '/terminos-y-condiciones-plataforma/',

  // External
  EXTERNAL: {
    BLOG: 'https://blog.trazable.io/',
    HIRE: 'https://www.linkedin.com/company/trazable/jobs/',
    LINKEDIN: 'https://www.linkedin.com/company/trazable',
  },

  // Token
  TOKEN: '/',
  PROFILE: '/profile/',
  VERIFY_ACCOUNT: '/verificar-cuenta/',
  PASSWORD_RECOVERY: '/password/recovery/',
  PASSWORD_RESET: '/password/reset/',
  REQUEST_RECEIVED: '/solicitud-recibida/',
} as const
