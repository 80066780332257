import { toast } from 'react-hot-toast'
import { EventEmitter } from 'events'
import { Notification, NotificationType } from '@blockchain-traceability-sl/tailwind-components'
import { useMemo } from 'react'
import { isObject } from '@/utils/is-object'
import { useTranslation } from 'react-i18next'
import i18n from '@/i18n'

const NotifyEventEmitter = new EventEmitter()
export enum NOTIFY_EVENT {
  ERROR = 'notify:error',
  WARN = 'notify:warn',
  CLEAR = 'notify:clear',
}

export interface NotifyBaseProps {
  title: string
  description: string
  actionText?: string
  onActionClick?: () => void
}

export interface NotifySuccessProps extends NotifyBaseProps {
  closeText?: string
  onClose?: () => void
  confirmStyle?: React.CSSProperties
}

export interface NotifyErrorProps extends NotifyBaseProps {
  actionText: string
  confirmStyle?: React.CSSProperties
}
export interface NotifyWarnProps extends NotifyBaseProps {
  actionText: string
  confirmStyle?: React.CSSProperties
}

interface UseNotifyInstance {
  success: (content: NotifySuccessProps) => string
  error: (content: NotifyErrorProps) => void
  warn: (content: NotifyWarnProps) => void
  clear: () => void
  onError: (handler: (props: NotifyErrorProps) => void) => void
  onWarn: (handler: (props: NotifyWarnProps) => void) => void
  onClear: (handler: () => void) => void
}

export const useNotify = (): UseNotifyInstance => {
  const { t } = useTranslation('nsNotifications')
  const onError = useMemo(
    () => (handler: (props: NotifyErrorProps) => void) =>
      NotifyEventEmitter.on(NOTIFY_EVENT.ERROR, handler),
    []
  )

  const onWarn = useMemo(
    () => (handler: (props: NotifyWarnProps) => void) =>
      NotifyEventEmitter.on(NOTIFY_EVENT.WARN, handler),
    []
  )

  const onClear = useMemo(
    () => (handler: () => void) => NotifyEventEmitter.on(NOTIFY_EVENT.CLEAR, handler),
    []
  )

  const success = useMemo(
    () =>
      (content: NotifySuccessProps): string => {
        return toast.custom(({ id, visible }) => (
          <Notification
            show={visible}
            type={NotificationType.SUCCESS}
            closeText={t('close')}
            {...content}
            onClose={() => {
              content.onClose && content.onClose()
              toast.dismiss(id)
            }}
            onActionClick={() => {
              content.onActionClick && content.onActionClick()
              toast.dismiss(id)
            }}
          />
        ))
      },
    [t]
  )

  const error = useMemo(
    () => (content: NotifyErrorProps) => {
      setTimeout(() => {
        NotifyEventEmitter.emit(NOTIFY_EVENT.ERROR, content)
      }, 100)
    },
    []
  )

  const warn = useMemo(
    () => (content: NotifyWarnProps) => {
      setTimeout(() => {
        NotifyEventEmitter.emit(NOTIFY_EVENT.WARN, content)
      }, 100)
    },
    []
  )

  const clear = useMemo(
    () => () => {
      setTimeout(() => {
        NotifyEventEmitter.emit(NOTIFY_EVENT.CLEAR)
      }, 100)
    },
    []
  )

  return {
    /**
     * Trigger a success notification
     *
     * @param content
     */
    success,

    /**
     * Trigger an error notification
     *
     * @param content
     */
    error,

    /**
     * Trigger a warning notification
     *
     * @param content
     */
    warn,

    /**
     * Trigger a clear notification
     */
    clear,

    /**
     * Listener an error notification
     */
    onError,

    /**
     * Listener a warning notification
     */
    onWarn,

    /**
     * Listener to clear notifications
     */
    onClear,
  }
}

export const customErrorToNotify = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  error: any,
  confirmStyle?: React.CSSProperties
): {
  title: string
  description: string
  actionText: string
  confirmStyle?: React.CSSProperties
} => {
  // Is not managed error
  if (!error || !isObject(error)) {
    return {
      title: i18n.t('nsNotifications:error.unknown.title'),
      description: i18n.t('nsNotifications:error.unknown.description'),
      actionText: i18n.t('nsNotifications:error.unknown.action'),
      confirmStyle,
    }
  }

  const errorCode: 'unknown' = error.code || 'unknown'

  return {
    title: i18n.t(`nsNotifications:error.${errorCode}.title`, { context: error.context }),
    description: i18n.t(`nsNotifications:error.${errorCode}.description`, {
      context: error.context,
    }),
    actionText: i18n.t(`nsNotifications:error.${errorCode}.action`),
    confirmStyle,
  }
}
