import { SegmentAnalytics } from '@segment/analytics.js-core'
import { useEffect, useRef, useState } from 'react'
import { isBrowser } from './utils'

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS & { load: (writeKey: string) => void }
    GATSBY_APP_API_URL: string
    GATSBY_APP_WEBSITE_URL: string
    GATSBY_APP_RECAPTCHA_SITEKEY: string
    GATSBY_APP_SEGMENT_WRITEKEY: string
    GATSBY_APP_VERSION: string

    // Token
    GATSBY_APP_SKU: string
    GATSBY_APP_STAKE_LP: string
    GATSBY_APP_BTX: string
    GATSBY_APP_PS: string
    GATSBY_APP_US: string
    GATSBY_APP_INFURA: string

    // ConfigCat
    GATSBY_APP_CONFIG_CAT_SDK_KEY: string
  }
}

export const Config = {
  ACCESS_TOKEN_STORAGE_NAME: 'jwt',
  COOKIES_CONFIG_STORAGE_NAME: 'trzCkConf',
  PAYMENT_DELAY_STORAGE_NAME: 'trzUPSP',

  API_URL: isBrowser() ? window.GATSBY_APP_API_URL : '',
  WEBSITE_URL: isBrowser() ? window.GATSBY_APP_WEBSITE_URL : 'https://trazable.io',
  RECAPTCHA_SITEKEY: isBrowser() ? window.GATSBY_APP_RECAPTCHA_SITEKEY : '',
  RECAPTCHA_HEADER: 'X-TRAZABLE-RECAPTCHA',
  SEGMENT_WRITEKEY: isBrowser() ? window.GATSBY_APP_SEGMENT_WRITEKEY : '',
  VERSION: isBrowser() ? window.GATSBY_APP_VERSION : '',

  // Token
  SKU: isBrowser() ? window.GATSBY_APP_SKU : 'https://tokensfarm.com/trz/staking/1',
  STAKE_LP: isBrowser() ? window.GATSBY_APP_STAKE_LP : 'https://tokensfarm.com/trz/lp/1',
  BTX: isBrowser()
    ? window.GATSBY_APP_BTX
    : 'https://global.bittrex.com/Market/Index?MarketName=USDT-TRZ',
  PS: isBrowser()
    ? window.GATSBY_APP_PS
    : 'https://pancakeswap.finance/swap?outputCurrency=0x52fe7b439753092f584917e3efea86a1cfd210f9&inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  US: isBrowser()
    ? window.GATSBY_APP_US
    : 'https://app.uniswap.org/tokens/bnb/0x52fe7b439753092f584917e3efea86a1cfd210f9',
  INFURA: isBrowser() ? window.GATSBY_APP_INFURA : '',

  // ConfigCat
  CONFIG_CAT_SDK_KEY: isBrowser()
    ? window.GATSBY_APP_CONFIG_CAT_SDK_KEY
    : process.env.GATSBY_APP_CONFIG_CAT_SDK_KEY,
}

export const useConfig = (): typeof Config => {
  const [globalConfig, setGlobalConfig] = useState(Config)
  const ref = useRef<boolean>(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isBrowser() && window.GATSBY_APP_API_URL && !ref.current) {
      ref.current = true
      setGlobalConfig(Config)
    }
  })

  return globalConfig
}
