import { defaultLanguage, languages } from '@/i18n'
// @ts-ignore
import { pageRouteTranslations } from '@/i18n/router'
import { navigate } from 'gatsby'

export const getCurrentPageLanguage = (pathname: string): string => {
  const pathElements = pathname.split('/')
  for (const element of pathElements) {
    for (const [lngCode] of languages) {
      if (element === lngCode) {
        return lngCode
      }
    }
  }
  return defaultLanguage
}

export const getTranslatedPath = (pathname: string, to: string): string => {
  const currentPageLanguage = getCurrentPageLanguage(pathname)

  let outputPath = to
  const isDefaultLanguage = defaultLanguage === currentPageLanguage
  if (!isDefaultLanguage) {
    outputPath = `/${currentPageLanguage}${pageRouteTranslations[to][currentPageLanguage]}`
  }

  const hasTrailingSlash = outputPath.endsWith('/')
  if (!hasTrailingSlash) {
    outputPath += '/'
  }

  return outputPath
}

export const changePathLanguage = (pathname: string, language: string): void => {
  const currentPageLanguage = getCurrentPageLanguage(pathname)

  let outputPath = pathname
  const isCurrentDefaultLanguage = defaultLanguage === currentPageLanguage

  if (currentPageLanguage === language) {
    return
  }

  if (isCurrentDefaultLanguage) {
    outputPath = `/${language}${pageRouteTranslations[pathname][language]}`
  } else {
    // Check what path is using the translated path
    const result = Object.entries(pageRouteTranslations).find(([, languagesRoutes]) => {
      return Object.entries(languagesRoutes as Record<string, string>).some(([, lngPath]) => {
        return lngPath === pathname.slice(pathname.slice(1).indexOf('/') + 1)
      })
    })

    if (result) {
      outputPath = result[0]
    }
  }

  if (outputPath !== pathname) {
    navigate(outputPath)
  }
}
